@import "_neat";
@import "../_mixins";
@import "../_variables";

#projects {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

#project {
  // max-width: 25rem;
  margin: 0 1rem 1rem 0;
  display: block;
  padding: 1rem;

  border: 1rem solid $color-black;

  &:hover {
    background: $color-shadow;
  }
}

#project-header {
  display: block;

  color: $color-black;
  text-decoration: none;
}

#project-content {
  max-width: 80%;
}
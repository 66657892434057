@import "_neat";
@import "../_mixins";
@import "../_variables";

.collapsed {
  height: 2.5rem;

  #links {
    overflow: hidden;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}

nav {
  width: 100%;
  padding: 1em 0em;
  display: block;

  box-shadow: 0px 1px $color-shadow;
  background: $color-black;

  #links {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;

    #hamburger { display: none; }

    @include respond-to("(max-width: 42rem)") {
      flex-direction: column;

      #hamburger {
        display: block;
        top: 0;
        left: 0;
        width: 2.5rem;
        height: 2.5rem;
        padding: 1rem;
        position: absolute;
      }
    }

    @include respond-to("(min-width: 42rem) and (min-width: 60rem)") {
      width: 59rem;
      margin: 1rem auto;
      justify-content: center;
    }
  }

  a {
    padding: 0 1rem 0 1rem;
    @include respond-to("(min-width: 60rem)") { }

    color: $color-white;
    font-size: 1.4rem;
    line-height: 2.5rem;
    text-align: center;
    text-decoration: none;

    &:hover {
      box-shadow: inset 0 -1.2rem $color-highlight;
    }
  }
}